import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../App';
import Hero from '../components/Hero';
import ProjectSection from '../components/ProjectSection';
import ContactSection from '../components/ContactSection';
import TechInfo from '../components/TechInfo';
import BlueDotsIcon from '../assets/icons/BlueDotsIcon';
import ProjectsPageHeroImage from '../gatsbyImages/ProjectsPageHeroImage';
import CourtlyMockupImage from '../gatsbyImages/CourtlyMockupImage';
import NexogenMockupImage from '../gatsbyImages/NexogenMockupImage';
import NamedropMockupImage from '../gatsbyImages/NamedropMockupImage';
// import TotlMockupImage from '../gatsbyImages/TotlMockupImage';
import SaasStoryMockup4Image from '../gatsbyImages/SaasStoryMockup4Image';
// import RecartMockupImage from '../gatsbyImages/RecartMockupImage';
import * as paths from '../paths';
import styles from './pages.module.scss';

const messages = defineMessages({
  title: {
    id: 'projectsPage.title',
    defaultMessage: 'Let Our Projects Do The {br} Talking'
  },
  description: {
    id: 'projectsPage.description',
    defaultMessage:
      'Take a closer look at what we’ve delivered, from software to results'
  },
  techUsed: {
    id: 'projectsPage.techUsed',
    defaultMessage: 'Tech used: '
  },
  courtlyPreTitle: {
    id: 'projectsPage.courtlyPreTitle',
    defaultMessage: 'Courtly x Streambright'
  },
  courtlyTitle: {
    id: 'projectsPage.courtlyTitle',
    defaultMessage:
      'How StreamBright helped Courtly create a customized CRM from idea to first users'
  },
  courtlyDescription: {
    id: 'projectsPage.courtlyDescription',
    defaultMessage:
      'Location: Washington, DC {br} Project Length: 2 years, ongoing {br}'
  },
  nexogenPreTitle: {
    id: 'projectsPage.nexogenPreTitle',
    defaultMessage: 'Nexogen x Streambright'
  },
  nexogenTitle: {
    id: 'projectsPage.nexogenTitle',
    defaultMessage:
      'That time StreamBright developed a customer dashboard for Nexogen’s Full Truckload'
  },
  nexogenDescription: {
    id: 'projectsPage.nexogennDescription',
    defaultMessage:
      'Location: Europe {br} Project Length: 9 months, ongoing {br}'
  },
  namedropPreTitle: {
    id: 'projectsPage.namedropPreTitle',
    defaultMessage: 'Namedrop x Streambright'
  },
  namedropTitle: {
    id: 'projectsPage.namedropTitle',
    defaultMessage:
      'How Namedrop got a validated software platform 4 weeks after initial user research'
  },
  namedropDescription: {
    id: 'projectsPage.namedropDescription',
    defaultMessage:
      'Location: Washington, DC {br} Project Length: 3 months {br}'
  },
  totlPreTitle: {
    id: 'projectsPage.totlPreTitle',
    defaultMessage: 'Totl x Streambright'
  },
  totlTitle: {
    id: 'projectsPage.totlTitle',
    defaultMessage:
      'How StreamBright helped revolutionize the world of data integration'
  },
  totlDescription: {
    id: 'projectsPage.totlDescription',
    defaultMessage:
      'Logistics product {br} Services: Frontend Development, Business Intelligence, Data Visualization {br} The numbers: 6 months {br}'
  },
  saasPreTitle: {
    id: 'projectsPage.saasPreTitle',
    defaultMessage: 'Leading Saas Video Editor x Streambright'
  },
  saasTitle: {
    id: 'projectsPage.saasTitle',
    defaultMessage:
      'How StreamBright took a leading video editor from popular hit to standout success'
  },
  saasDescription: {
    id: 'projectsPage.saasDescription',
    defaultMessage:
      'Location: New York City {br} Project Length: 1 year, ongoing {br}'
  },
  recartPreTitle: {
    id: 'projectsPage.recartPreTitle',
    defaultMessage: 'Recart x Streambright'
  },
  recartTitle: {
    id: 'projectsPage.recartTitle',
    defaultMessage:
      'StreamBright takes Recart’s high-growth app from MVP to high-functioning product'
  },
  recartDescription: {
    id: 'projectsPage.recartDescription',
    defaultMessage:
      'E-commerce messenger marketing plugin {br} Team extension: {br} Ongoing Services: Tech Consulting, Tech Debt Elimination, Feature Development {br}'
  },
  button: {
    id: 'projectsPage.button',
    defaultMessage: 'Read case study'
  },
  contactSectionTitle: {
    id: 'projectsPage.contactSectionTitle',
    defaultMessage: 'Let’s build a new project together'
  },
  contactSectionDescription: {
    id: 'projectsPage.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  react: {
    id: 'projectsPage.react',
    defaultMessage: 'React'
  },
  java: {
    id: 'projectsPage.java',
    defaultMessage: 'Java'
  },
  reactNative: {
    id: 'projectsPage.reactNative',
    defaultMessage: 'React Native'
  },
  aws: {
    id: 'projectsPage.aws',
    defaultMessage: 'AWS'
  },
  typescript: {
    id: 'projectsPage.typescript',
    defaultMessage: 'Typescript'
  }
});

function ProjectsPage() {
  return (
    <App>
      <Helmet>
        <title>Streambright - From Software to Results</title>
        <meta
          name="description"
          content="Let our projects do the talking. Take a closer look at what we’ve delivered, from software to results."
        />
      </Helmet>
      <Hero
        icon={<BlueDotsIcon />}
        title={<FormattedMessage {...messages.title} values={{ br: <br /> }} />}
        description={messages.description}
        backgroundImage={ProjectsPageHeroImage}
      />
      <ProjectSection
        image={CourtlyMockupImage}
        imageClassName={styles.courtlyMockup}
        preTitle={messages.courtlyPreTitle}
        title={messages.courtlyTitle}
        description={
          <>
            <FormattedMessage
              {...messages.courtlyDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.java} />
              <TechInfo title={messages.aws} />
              <TechInfo title={messages.reactNative} />
            </div>
          </>
        }
        icon={
          <div className={styles.leftIcon}>
            <BlueDotsIcon />
          </div>
        }
        to={paths.courtly()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={NexogenMockupImage}
        imageClassName={styles.nexogenMockup}
        preTitle={messages.nexogenPreTitle}
        title={messages.nexogenTitle}
        description={
          <>
            <FormattedMessage
              {...messages.nexogenDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
            </div>
          </>
        }
        reverse
        variant="secondary"
        to={paths.nexogen()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={NamedropMockupImage}
        imageClassName={styles.namedropMockup}
        preTitle={messages.namedropPreTitle}
        title={messages.namedropTitle}
        description={
          <>
            <FormattedMessage
              {...messages.namedropDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.java} />
              <TechInfo title={messages.aws} />
              <TechInfo title={messages.reactNative} />
            </div>
          </>
        }
        to={paths.namedrop()}
        buttonText={messages.button}
      />
      {/* <ProjectSection
        image={TotlMockupImage}
        imageClassName={styles.courtlyMockup}
        preTitle={messages.totlPreTitle}
        title={messages.totlTitle}
        description={
          <>
            <FormattedMessage
              {...messages.totlDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.java} />
              <TechInfo title={messages.aws} />
              <TechInfo title={messages.reactNative} />
            </div>
          </>
        }
        reverse
        to={paths.dataIntegration()}
        buttonText={messages.button}
      /> */}
      <ProjectSection
        image={SaasStoryMockup4Image}
        imageClassName={styles.saasStoryMockup}
        preTitle={messages.saasPreTitle}
        title={messages.saasTitle}
        description={
          <>
            <FormattedMessage
              {...messages.saasDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.typescript} />
            </div>
          </>
        }
        variant="secondary"
        reverse
        to={paths.saasStory()}
        buttonText={messages.button}
      />
      {/*      <ProjectSection
        image={RecartMockupImage}
        preTitle={messages.recartPreTitle}
        title={messages.recartTitle}
        description={
          <>
            <FormattedMessage
              {...messages.recartDescription}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.typescript} />
            </div>
          </>
        }
        variant='secondary'
        to={paths.recart()}
        buttonText={messages.button}
      /> */}
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default ProjectsPage;
